import { GridQuestionSectionEnum, GridQuestionTypeEnum } from './grid-question';

export class PostGridQuestionSearchBody {
  fields = [
    "id",
    "name",
    "displayed_name",
    "user_id",
    "updated_at",
    "type"
    ];
  filters: PostFilter;
  limit = 10;

  constructor(
      type: GridQuestionTypeEnum | undefined,
      limit: number,
  ) {
    this.filters = {
        type: type,
        is_active: true,
    };
    this.limit = limit;
  }
}


export interface PostFilter {
  type?: GridQuestionTypeEnum,
  is_active?: boolean,
}

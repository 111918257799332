import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from '../../../common-explain/decorators/auto-unsubscribe';
import { ApiTenderService } from '../../services/api/api-tender.service';
import { firstValueFrom } from 'rxjs';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';
import { GridQuestion, GridQuestionFormActionEnum, GridQuestionSectionEnum } from '../../models/grid-question';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationWithCheckbox } from '../../../components/modal-confirmation-with-checkbox/modal-confirmation-with-checkbox';
import { SmartGridService } from '../../services/smart-grid.service';

@Component({
  selector: 'app-tender-grid-question-form',
  templateUrl: './tender-grid-question-form.html',
  styleUrls: ['./tender-grid-question-form.scss']
})
@AutoUnsubscribe
export class TenderGridQuestionFormComponent {
  @Input() isVisible: boolean = false;
  @Input() action!: GridQuestionFormActionEnum;
  @Input() questionToEdit: GridQuestion | null = null;
  @Output() questionAdded: EventEmitter<void> = new EventEmitter<void>();
  @Output() questionEdited: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionDeleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formClosed: EventEmitter<void> = new EventEmitter<void>();

  name: string = '';
  displayedName: string = '';
  loading = false;
  questionId: number | null = null;

  @ViewChild('errorInAddingQuestionToast', {read: TemplateRef}) errorInAddingQuestionToast!: TemplateRef<any>;
  @ViewChild('errorInEditingQuestionToast', {read: TemplateRef}) errorInEditingQuestionToast!: TemplateRef<any>;
  @ViewChild('successInEditingQuestionToast', {read: TemplateRef}) successInEditingQuestionToast!: TemplateRef<any>;
  @ViewChild('successInDeletingQuestionToast', {read: TemplateRef}) successInDeletingQuestionToast!: TemplateRef<any>;

  constructor(
    private apiTenderService: ApiTenderService,
    private userTrackerService: UserTrackerService,
    private toastMessageStackService: ToastMessageStackService,
    private smartGridService: SmartGridService,
    private modalService: NgbModal,
  ) {
  }

  ngOnChanges() {
    this.displayedName = this.questionToEdit?.displayedName || '';
    this.name = this.questionToEdit?.name || '';
    this.questionId = this.questionToEdit?.questionId || null;
  }


  initForm() {
    this.loading = false;
    this.formClosed.emit();
  }

  async addQuestion() {
    this.loading = true;
    firstValueFrom(this.apiTenderService.gridQuestion.addGridQuestion(this.name, this.displayedName))
      .then(() => {
        this.trackEvent({
            questionName: this.name,
            displayedName: this.displayedName
          },
          EventTypeName.CUSTOM_QUESTION_ADDED
        );
      })
      .catch(() => {
        this.toastMessageStackService.show(this.errorInAddingQuestionToast, {autohide: true, classname: 'error-toast toast-shape'});
      })
      .finally(() => {
        this.initForm();
        this.questionAdded.emit();
      });
  }

  openEditConfirmationModal() {
    const modal = this.modalService.open(ModalConfirmationWithCheckbox, {ariaLabelledBy: 'modal-basic-title', centered: true});
    modal.componentInstance.updateMode = true;
    modal.componentInstance.translationFileSection = 'tenders.tender-detail.private-question-form';
    modal.componentInstance.onValidateAction = this.editQuestion.bind(this);
  }

  async openDeleteConfirmationModal() {
    const modal = this.modalService.open(ModalConfirmationWithCheckbox, {ariaLabelledBy: 'modal-basic-title', centered: true});
    modal.componentInstance.deleteMode = true;
    modal.componentInstance.translationFileSection = 'tenders.tender-detail.private-question-form';
    modal.componentInstance.onValidateAction = this.deleteQuestion.bind(this);
    modal.componentInstance.numberOfUsersPinningQuestion =await  this.smartGridService.getNumberOfUsersPinningQuestion(this.questionId)
  }

    async editQuestion() {
        this.loading = true;
        if (this.questionToEdit) {
            try {
                await this.smartGridService.editQuestion(this.questionToEdit, this.name, this.displayedName);
                this.trackEvent({
                        questionName: this.name,
                        displayedName: this.displayedName,
                        questionId: this.questionId
                    },
                    EventTypeName.CUSTOM_QUESTION_EDITED
                );
                this.toastMessageStackService.show(this.successInEditingQuestionToast, {autohide: true, classname: 'success-toast toast-shape'});
            } catch (e) {
                this.toastMessageStackService.show(this.errorInEditingQuestionToast, {autohide: true, classname: 'error-toast toast-shape'});
            }
            this.initForm();
            this.questionEdited.emit(this.questionToEdit.questionId);
        }
    }

  async deleteQuestion() {
    this.loading = true;
    if (this.questionId) {
      await this.smartGridService.deleteQuestion(this.questionId)
        .then(() => {
          this.trackEvent({
              questionName: this.name,
              displayedName: this.displayedName,
              questionId: this.questionId
            },
            EventTypeName.CUSTOM_QUESTION_DELETED
          );
          this.toastMessageStackService.show(this.successInDeletingQuestionToast, {autohide: true, classname: 'success-toast toast-shape'});
          if(this.questionId){
            this.questionDeleted.emit(this.questionId);
          }
        })
        .catch(() => {
          this.toastMessageStackService.show(this.errorInEditingQuestionToast, {autohide: true, classname: 'error-toast toast-shape'});
        })
        .finally(() => {
          this.initForm();
        });
    }
  }

  close() {
    this.initForm();
  }

  trackEvent(data = {}, eventName = '') {
    firstValueFrom(this.userTrackerService.track(
      {
        event_type: eventName,
        event_timestamp: (new Date()).toISOString(),
        ...this.userTrackerService.buildBasicUserInformations(),
        ...data
      }
    ));
  }
}
